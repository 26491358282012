import React, { useState } from 'react';
import './header.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logoTransparent from '../../assets/logo-audiyoga-transparent.png';



const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
  <header>
    <div className="gpt3__header-background">

    <nav aria-label="Hauptnavigation" role="Hauptnavigation" className="gpt3__navbar">
      <div className="gpt3__navbar-links">

        <div className="gpt3__navbar-links_container">
          <p><a tabindex="0"   href="#home" title="zur Startseite">HOME</a></p>
          <p><a tabindex="0"   href="#possibility" title="zur Hörprobe">AUDIO</a></p>
          <p><a tabindex="0"   href="#footer" title="zu Kontaktinformationen">KONTAKT</a></p>
        </div>
      </div>

      <nav role="Mobile Navigation" aria-label="mobiles Menü" className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine tabindex="0" aria-label="Mobile Navigation schließen" color="#875C80" size={27} onKeyPress={(e) => e.key === 'Enter' && setToggleMenu(false)}  onClick={() => setToggleMenu(false)} /> /* KEYPRESS MUSS NOCH AUF ENTER GEBUNDEN WERDEN */
          : <RiMenu3Line tabindex="0" aria-label="Mobile Navigation öffnen" color="#875C80" size={27} onKeyPress={(e) => e.key === 'Enter' && setToggleMenu(true)} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <p><a tabindex="0"  href="#home">HOME</a></p>
            <p><a tabindex="0"  href="#possibility">AUDIO</a></p>
            <p><a tabindex="0"  href="#footer">KONTAKT</a></p>
          </div>
        </div>
        )}
      </nav>
    </nav>

      <div className="gpt3__header section__padding" id="home">
        
        <div className="gpt3__header-content">
          <img src={logoTransparent} alt="logo audiyoga" />
          <h1 tabindex="0" className="gpt3__header-headline">AUDIYOGA</h1>
          <p tabindex="0"> Lerne Yoga nur über dein Gehör</p>


          <div className="gpt3__header-content__input gpt3__navbar-links_container">
          <p><a tabindex="0"   href="#possibility" >Jetzt ausprobieren</a></p>
          </div>
        </div>
      </div>

    </div>  
  </header>

)};

export default Header;
