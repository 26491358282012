import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';
import logo3 from '../../assets/logo3.png';
import audiofile from '../../assets/Audiyoga-layout1.wav';
import FeatureEvery from '../../assets/Feature-everywhere.png';
import FeatureHeadphone from '../../assets/Feature-headphone.png';
import FeaturePlay from '../../assets/Feature-playbutton.png';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Player = () => (
  <AudioPlayer
    autoPlay
    onPlay={e => console.log("onPlay")}
    
  />
);

const Features = () => (


<main>
  <div className="gpt3__features" id="features">
    <div className="gpt3__features-container">
        <div tabindex="0" className="gpt3__features-container__feature feature-color-1">
          <div className="gpt3__features-container__feature-icon">
            <img src={FeatureEvery} alt=""/>
          </div>
          <div className="gpt3__features-container__feature-headline">
            <h2>ÜBERALL</h2>
          </div>
          <div className="gpt3__features-container__feature-text">
            <p>Wo und wann du Yoga machen möchtest liegt alleine bei dir. Im Wald,  am Strand oder im Büro. Auch ohne Internet.</p>
          </div>
        </div>

        <div tabindex="0" className="gpt3__features-container__feature feature-color-2 feature-white-text">
          <div className="gpt3__features-container__feature-icon">
            <img src={FeatureHeadphone} alt="" />
          </div>
          <div className="gpt3__features-container__feature-headline">
            <h2>FÜR DICH</h2>
          </div>
          <div className="gpt3__features-container__feature-text">
            <p>Durch die Reduzierung auf Audio wird der Fokus intensiviert. Du bist ganz bei dir, deiner Atmung und der jeweiligen Übung</p>
          </div>
        </div>

        <div tabindex="0" className="gpt3__features-container__feature feature-color-3">
          <div className="gpt3__features-container__feature-icon">
            <img src={FeaturePlay} alt="" />
          </div>
          <div className="gpt3__features-container__feature-headline">
            <h2>ZUGÄNGLICH</h2>
          </div>
          <div className="gpt3__features-container__feature-text">
            <p>Wir wollen Yoga möglichst vielen Menschen zugänglich machen. Die Übungen werden so detailliert beschrieben, dass kein Video erforderlich ist.</p>
          </div>
        </div>
    </div>
  </div>


  <div className="gpt3__possibility parallax" id="possibility" style={{ 
  backgroundImage: `url(${process.env.PUBLIC_URL + 'https://abload.de/img/parralaxgmk51.png'})` 
}}>



<div className="gpt3__possibility-content">
<h1 tabindex="0">Jetzt loslegen</h1>
<h2 tabindex="0">Starte jetzt mit unserer Hörprobe auf YouTube</h2>

<div className="video-container">
<a class="video-tile-link" title="Öffnet Yoga Nidra Video auf YouTube in neuem Browserfenster" target="_blank" href="https://www.youtube.com/watch?v=p5qAHa4wlKs"><div className="video-tile video-tile-first">
  <h3>Yoga Nidra</h3>
  <p>Yoga Nidra - Nidra bedeutet Schlaf. Nidra ist eine Methode, um nicht nur körperliche, sondern auch mentale und emotionale Entspannung zu erreichen. Es heißt, dass 30 Min. Yoga Nidra 2 Stunden Schlaf ersetzen können.</p>
  <svg width="128" height="130" viewBox="0 0 128 130" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="64" cy="65" rx="64" ry="65" fill="#875C80"/>
<path d="M86.9378 59.2787C91.9929 61.886 91.9929 69.114 86.9378 71.7213L52.9587 89.2465C48.2998 91.6494 42.75 88.2673 42.75 83.0253L42.75 47.9747C42.75 42.7327 48.2998 39.3506 52.9587 41.7535L86.9378 59.2787Z" fill="white"/>
</svg>

</div></a>

<div className="video-tile-spacer"></div>

<a class="video-tile-link" title="Öffnet Yoga für Blinde Video auf YouTube in neuem Browserfenster" target="_blank" href="https://www.youtube.com/watch?v=RdFsQwKh7x4"><div className="video-tile">
  <h3>Yoga für Blinde</h3>
  <p>Yoga für Blinde | 20 minütige Yogasequenz für Einsteiger.</p>
  <p> audiyoga - Lerne Yoga nur über dein Gehör</p>
  <svg width="128" height="130" viewBox="0 0 128 130" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="64" cy="65" rx="64" ry="65" fill="#875C80"/>
<path d="M86.9378 59.2787C91.9929 61.886 91.9929 69.114 86.9378 71.7213L52.9587 89.2465C48.2998 91.6494 42.75 88.2673 42.75 83.0253L42.75 47.9747C42.75 42.7327 48.2998 39.3506 52.9587 41.7535L86.9378 59.2787Z" fill="white"/>
</svg>

</div> </a> 
</div>


{/*<AudioPlayer
    autoPlay
    src={audiofile}
    onPlay={e => console.log("onPlay")}
  />
*/}

</div>


</div>


  </main>
);

export default Features;
