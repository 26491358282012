import React from 'react';
import { Footer, Features, Header } from './containers';
import './App.css';





function App() {


  return (

    <div className="App">
      
    <Header />
    <Features />
    <Footer />


    </div>
  )
}


export default App;
