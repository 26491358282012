import React from 'react';
import gpt3Logo from '../../logo.svg';
import './footer.css';
import footerLogo from '../../assets/LogoFooter.png';

const Footer = () => (
  <footer className="gpt3__footer" id="footer">


    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={footerLogo} alt="Footer logo" />  
        <p><a title="Link anklicken um Mail an info@audiyoga.de zu schicken" href="mailto:info@audiyoga.de?subject=Kontakt%20Anfrage">info@audiyoga.de</a></p>
      </div>

    </div>

    <div className="gpt3__footer-copyright">
    <nav role="footer-navigation" className="gpt3__footer-copyright-links">
      <a  data-bs-toggle="modal" data-bs-target="#exampleModal" title="zur Datenschutzseite">Datenschutz</a>
      <a  data-bs-toggle="modal" data-bs-target="#exampleModal2" title="zur Impressumsseite">Impressum</a>
    </nav>
      <p>@2022 AUDIYOGA. All rights reserved.</p>
    </div>


    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Datenschutzerklärung</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      

<p>Wir möchten Sie nachfolgend darüber informieren, welche personenbezogenen Daten bei der Nutzung der Webseite www.audiyoga.de anfallen, zu welchem Zweck diese verarbeitet werden und welche Rechte Ihnen in Bezug auf die Sie betreffenden Daten zur Seite stehen.</p>

<p>Ihre Daten werden von uns grundsätzlich nicht an Dritte weitergegeben, es sei denn, es besteht eine gesetzliche Verpflichtung oder es liegt eine ausdrückliche Erlaubnis (wie z. B. eine Einwilligung von Ihnen) vor.</p>

<p>Datenschutzrechtlich verantwortlich und Ansprechpartnerin für weitere Fragen zum Thema Datenschutz ist:</p>

<p>Beatrice Goihl, Volksgartenstr. 23, 50677 Köln. Sie erreichen mich unter mail[at]beatricegoihl.de</p>

<p><strong>Daten mit unmittelbarem Personenbezug</strong></p>

<p>Hierbei handelt es sich um Daten wie Ihren Namen, Ihre Anschrift oder Ihr Geburtsdatum. Solche oder andere Daten, die sich unmittelbar auf Sie als Person beziehen erhalte ich nur, wenn Sie Kontakt zu mir aufnehmen.</p>

<p>Wenden Sie sich z.B. per E-Mail an uns, speichern wir Ihren Namen, Ihre Kontaktdaten und den Grund Ihres Anliegens, um dieses bearbeiten zu können.</p>

<p>Daneben kommt eine Verarbeitung insbesondere zur Wahrung gesetzlicher Aufbewahrungsfristen in Betracht.</p>

<p><strong>Daten mit mittebarem Personenbezug</strong></p>

<p>Immer wenn eine Webseite aufgerufen wird, fordert der Browser bei einem sogenannten DSN-Server die Zusendung von Daten an.</p>

<p>Bei diesem Vorgang wird von Ihrem Computer Ihre IP-Adresse (und weitere gerätebezogene Daten, wie z.B. der Browser den Sie verwenden oder die Webseite von der Sie gekommen sind) übertragen und von dem jeweiligen Server gespeichert.</p>

<p>Da man (unter Umständen) auch anhand Ihrer IP-Adresse Rückschlüsse auf Ihre Person ziehen kann, möchten wir Sie darüber informieren, dass diese gerätebezogenen Daten lediglich aus Gründen der Stabilität und Funktionalität dieser Webseite oder zur Verfolgung eines etwaigen rechtswidrigen Angriffs auf diese gespeichert und unmittelbar gelöscht werden, nachdem Sie für diese Zwecke nicht mehr erforderlich sind.</p>

<p>Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>

<p><strong>Ihre Rechte in Bezug auf die Sie betreffenden Daten:</strong></p>
<p>Sie dürfen jederzeit Ihre Einwilligung widerrufen, mit der Konsequenz, dass wir Ihre Daten, die wir lediglich aufgrund Ihrer Einwilligung verarbeiten durften, dann künftig nicht mehr verarbeiten dürfen.</p>

<p>Auch können Sie Ihr sogenanntes Auskunftsrecht ausüben, also das Recht Kenntnis darüber zu erhalten, welche Sie betreffenden personenbezogenen Daten zu welchen Zwecken für welchen Zeitraum von mir verarbeitet werden und welche Rechte Ihnen diesbezüglich zustehen.</p>

<p>Sollten Daten veraltet, unvollständig oder fehlerhaft sein, können Sie die Berichtigung oder Vervollständigung verlangen. Außerdem haben Sie das Recht, die Löschung oder zumindest die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten zu verlangen, soweit diese nicht aus anderen Gründen wie z.B. gesetzlichen Aufbewahrungspflichten (noch) nicht vollständig gelöscht werden können.</p>

<p>Wenn Sie der Ansicht seien sollten, dass datenschutzrechtliche Vorschriften nicht eingehalten werden, können Sie sich außerdem jederzeit an eine zuständige Aufsichtsbehörde (z. B. die nächste an Ihrem Wohnsitz) wenden.</p>

<p>Die für uns zuständige Aufsichtsbehörde ist die Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen – LDI  in der Kavalleriestr. 2-4 in 40213 Düsseldorf.</p>

<p>Stand: 08/2022</p>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Impressum</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      
<p>Verwantortlich im Sinne des § 5 TMG und § 55 Abs. 2 RStV:</p>
<p></p>
<p>audiyoga Gbr</p>
<p>Beatrice Goihl</p>
<p>Volksgartenstr. 23</p>
<p>50677 Köln</p>

<p>E-Mail: info@audiyoga.de</p>


      </div>
    </div>
  </div>
</div> 




  </footer>
);

export default Footer;
